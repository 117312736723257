import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';

import { theme } from '../styles/GlobalStyle';
import { useWindowWidth } from '../../hooks/useWindowSize';

import { TopOfFold } from '../page-components-copy/top-of-fold';
import { ColorBanner } from '../page-components-copy/color-banner';
import { ImageAndList } from '../page-components-copy/image-and-list';

import {
  Bold,
  ButtonLink,
  Paragraph,
  SectionTitle,
  Spacer,
  UnbiasedResources,
} from '../page-components-copy/components';
import { IconFocusCTA } from '../page-components-copy/icon-focus-cta';
import PartnerLanding, { PARTNER } from '../Better/landing';
import { ROUTE_PATHS } from '../_pages/_utils/router.utils';
import { useAuthContext } from '../../providers/auth.provider';
import { IMAGES, PLAN_PRICE, getImage, handleTap } from '../../utils';
import TestimonialSection from '../testimonials';
import { MarketingStats } from './MarketingStats';

const person1 = getImage(IMAGES.PERSON_1.ref);
const Magnify = getImage(IMAGES.MAGNIFY.ref);
const Square1 = getImage(IMAGES.SQUARE_1.ref);
const Square2 = getImage(IMAGES.SQUARE_2.ref);
const Square3 = getImage(IMAGES.SQUARE_3.ref);
const Square4 = getImage(IMAGES.SQUARE_4.ref);
const LaptopWithMug = getImage(IMAGES.LAPTOP_BLUE_MUG.ref);
const BalloonDog = getImage(IMAGES.BALLOON_DOG.ref);
const CoffeeAndPencil = getImage(IMAGES.COFFEE_AND_PENCIL.ref);

const StyledImageAndList = styled(ImageAndList)`
  & > div:last-child {
    @media ${({ theme }) => theme.mq.desktopLargeMin} {
      max-width: 640px !important;
      margin-left: 32px !important;
    }
    & p span:last-child {
      font-size: 21px;
    }
  }
`;

const Image = styled('img')`
  display: flex;
  z-index: -1;
  margin-top: 24px;
  width: 210px;
  margin: auto;
  @media ${theme.mq.desktopLargeMin} {
    width: 325px;
  }
`;

const getCampaignCopy = (campaign) => {
  if (campaign === 'consolidation') {
    return {
      h1Copy: 'Lower Your Payments and Save in Minutes',
      pCopy:
        'Refinance and consolidation for any credit - instantly apply across thousands of lenders at touch of a button (with no impact on your score). Then continue to monitor your credit and savings opportunities. Destress your financial life - we’re free if we can’t help.',
    };
  } else if (campaign === 'credit-score') {
    return {
      h1Copy: 'Increase Your Score Or Your Money Back.',
      pCopy: `Want to raise your score and qualify for new lower cost loans? Wondering what’s in your credit score? We work on your score and lowering debt burdens all in one place. We scan for savings, identify mistakes on your report and help you correct. Then we track and continuously monitor for you. Without spam. Only $${PLAN_PRICE}/month and a “Results or Your Money-Back” Guarantee - if we don’t help, we don’t get paid.`,
    };
  } else if (campaign === 'save') {
    return {
      h1Copy: 'Save Money and Lower Your Monthly Debt Payments',
      h2Copy:
        'Effortlessly navigate to lower cost borrowing. We’ve identified over $1,000/year savings on average.',
      pCopy: `Quickly scan your credit report against 1000s of lenders for savings. $${PLAN_PRICE} a month, risk-free – if we don't find ways to save, get your money back. In addition to lowering your monthly or total cost of debt, we also help monitor and improve your credit score, all in one debt dashboard. Ask us any borrowing question you have.`,
    };
  } else if (campaign === 'ham') {
    document.title = 'We Optimize Your Home-Buying Power | Solve Finance';

    return {
      h1Copy: 'Expert Help For Your Home-Buying Power',
      h2Copy:
        'Effortlessly navigate to home-buying readiness. We help get you to your dream by working on your homebuying power and finances for you.',
      pCopy: `Quickly scan your credit report against 1000s of lenders for savings. $${PLAN_PRICE} a month, risk-free – if we don't find ways to save, get your money back. In addition to lowering your monthly or total cost of debt, we also help monitor and improve your credit score, all in one debt dashboard. Ask us any borrowing question you have.`,
      cta: 'Increase My Homebuying Power',
    };
  } else if (campaign === 'cash') {
    return {
      h1Copy: (
        <>
          <b>Our Debt Optimizer Makes</b>
          <br />
          <span>Better Borrowing Easy</span>
        </>
      ),
      h2Copy: 'Get Cash Today With Our Debt Management App As Your Co-Pilot',
      pCopy:
        'Effortlessly navigate credit. We get more borrowers into loans to get them cash today, improving credit score and finding savings of over $1,000 a year on average.',
    };
  } else {
    return {
      h1Copy: (
        <>
          <b>Our Debt Optimizer Makes</b>
          <br />
          <span>Better Borrowing Easy</span>
        </>
      ),
      h2Copy:
        'Easily Improve Your Finances With Our Debt Management App As Your Co-Pilot',
      pCopy:
        'Effortlessly navigate credit. Whatever your goal is, we’re here to help. We get more borrowers into homes and loans, increase home-buying power > 25% and find savings of over $1,000 a year on average.',
    };
  }
};

const getCampaignFromParams = (params: URLSearchParams): string => {
  const utmContent = params.get('utm_content');
  const utmCampaign = params.get('utm_campaign');

  switch (utmContent) {
    case '141553451459':
    case 'save':
      return 'save';
    case '152360798065':
    case 'consolidation':
      return 'consolidation';
    case '138609779462':
    case '171380616145':
    case 'credit-score':
      return 'credit-score';
    default:
      return utmCampaign || utmContent;
  }
};

const solveDifferenceListItems = [
  {
    icon: null,
    text: (
      <span>
        <Bold>Get simple, unbiased advice.</Bold>
        <br />
        You choose whenever to share your data. We offer a premium service
        without advertising and clear, unbiased information from a team of PhDs
        to help you understand your options and next steps.
      </span>
    ),
  },
  {
    icon: null,
    text: (
      <span>
        <Bold>We take your privacy seriously. (Seriously.)</Bold>
        <br />
        Ever given out your phone or email in exchange for a price quote and
        then get bombarded with robocalls and sales messages? Yea, we don’t do
        that.
      </span>
    ),
  },
  {
    icon: null,
    text: (
      <span>
        <Bold>We make it fast and easy.</Bold>
        <br />
        Our financial AI helps you make better borrowing decisions to save
        money, get more money out, or buy more home.
      </span>
    ),
  },
];

const howItWorksItems = [
  {
    icon: <img src={Square1} />,
    text: (
      <span>
        <Bold>Tell us your goal:</Bold> pay off debts, pay less, improve score,
        or maximize home purchasing power.
      </span>
    ),
  },
  {
    icon: <img src={Square2} />,
    text: (
      <span>
        <Bold>Answer a few questions</Bold> about your current financial
        situation.
      </span>
    ),
  },
  {
    icon: <img src={Square3} />,
    text: (
      <span>
        <Bold>The Debt Optimizer works its unbiased, tech magic</Bold> after
        getting your credit report and score.
      </span>
    ),
  },
  {
    icon: <img src={Square4} />,
    text: (
      <span>
        <Bold>Evaluate your options</Bold> and the associated improvements to
        your credit score, purchasing power, or monthly payments.
      </span>
    ),
  },
];

const Home: React.FC<{ className?: string }> = ({ className }) => {
  const { handleSignUp } = useAuthContext();
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const campaign:
    | 'credit-score'
    | 'consolidation'
    | 'save'
    | 'ham'
    | 'cash'
    | string = getCampaignFromParams(params) || '';
  const { isMobile } = useWindowWidth();
  const source =
    params.get('utm_source') ||
    params.get('utm_content') ||
    localStorage.getItem('solve_source');

  if (source === 'renofi' && process.env.API_URL.includes('staging')) {
    window.location.href = 'https://app.solve.finance?utm_source=renofi';
  }

  const partner = PARTNER[source];
  if (partner) return <PartnerLanding partner={partner} campaign={campaign} />;

  const copy = getCampaignCopy(campaign);

  const onSignUp = async () => {
    handleSignUp();
    navigate(ROUTE_PATHS.ONBOARDING);
  };

  return (
    <div className={className}>
      <TopOfFold
        alt="A magnifying glass searching for better borrowing options"
        copy={copy}
        copyWidth="100%"
        ctas={[
          {
            bgColor: theme.green4,
            callback: onSignUp,
            cta: copy.cta || 'Get started',
            testid: 'saving-button',
          },
        ]}
        ImageOverride={
          isMobile ? (
            <Image
              src={Magnify}
              style={{
                transform: 'rotate(180)',
                position: 'absolute',
                right: '-64px',
                top: '-48px',
                opacity: 0.25,
              }}
            />
          ) : (
            <Image src={Magnify} />
          )
        }
        hasMobileDownload={campaign === 'ham'}
        continueOnWeb={onSignUp}
        imageLocation={{ desktop: 'left', mobile: 'top' }}
      />
      <Box mt={6}>
        <MarketingStats />
        <TestimonialSection pb={2} />
      </Box>
      <Box>
        <ColorBanner
          padding="24px 0 48px"
          bannerColor="#fafafa"
          copyColor="#222"
          copyAlignment="center"
          copy={{
            pCopy: (
              <span style={{ fontSize: '1.3rem' }}>
                <span style={{ display: 'block', color: `${theme.blue4}` }}>
                  Quickly get expert assistance and explore your options at no
                  cost.
                </span>
                <br />{' '}
                <span
                  style={{ fontFamily: 'Majorant-Light', fontSize: '1rem' }}
                >
                  Without spam or limits to our advice (as you’re our customer).
                  Onboarding with our debt management app only takes minutes. We
                  then provide unbiased financial expertise and advice tailored
                  to your needs and goals, whether that’s lowering your monthly
                  debt payments and debt-to-income ratio, improving your credit
                  score, or increasing your home-buying power.
                </span>
              </span>
            ),
          }}
        />
      </Box>
      <ColorBanner
        bannerColor="#F2994A"
        copy={{
          headerCopy: 'Quickly Shop and Qualify for Loans',
          pCopy:
            'Your debts cost you money. That’s the interest you pay across credit cards, personal, home, auto, and student loans—and as you know, it can add up fast. And if your credit score isn’t where it could be or you were just denied a loan, we handle that too.',
        }}
        imageMeta={{
          src: person1,
          mobilePosition: 'bottom',
          alt: 'A borrower living her stress-free life after Solve Finance fixes credit for her',
        }}
      />
      <Spacer />
      <Spacer />
      <Spacer />
      <Spacer />
      <ImageAndList
        title="How it works"
        image={
          <img
            src={LaptopWithMug}
            alt="A computer chugging away to optimize debts and scan all your options for you"
          />
        }
        ctas={[]}
        listItems={howItWorksItems}
      />
      <Spacer />
      {!isMobile && (
        <ButtonLink
          onClick={onSignUp}
          bgColor={theme.green4}
          color={theme.white}
          style={{ minWidth: 227, margin: 'auto' }}
        >
          Get Started
        </ButtonLink>
      )}
      <div>
        <SectionTitle>Put your borrowing on automatic</SectionTitle>
        <Paragraph>
          We organize your debts and help you apply across 1000s of lenders. Our
          debt management app even continues to check for savings as the
          market—and your situation—changes.
        </Paragraph>
      </div>
      <Spacer />
      {!isMobile && (
        <>
          <StyledImageAndList
            title="The Solve Difference"
            image={
              <img
                src={CoffeeAndPencil}
                alt="A coffee cup after you sit back and relax letting Solve do the work on optimizing borrowing, fixing credit, and qualifying you for savings"
              />
            }
            ctas={[
              {
                cta: 'Get started',
                callback: onSignUp,
                color: theme.white,
                bgColor: theme.red4,
              },
            ]}
            listItems={solveDifferenceListItems}
            showGrid
          />
          <Spacer />
        </>
      )}

      {isMobile ? (
        <>
          <IconFocusCTA
            icon={<img src={BalloonDog} alt="balloon animal dog" />}
            title={{
              text: isMobile
                ? null
                : 'Get to easier, better borrowing outcomes today',
              size: 'l',
            }}
            paragraph={
              isMobile
                ? null
                : 'Try our Debt Optimizer app to quickly navigate to your goal. We’re your borrowing co-pilot for improving credit outcomes and avoiding costly mistakes.'
            }
            ctas={[
              {
                text: 'Get Started',
                callback: onSignUp,
                bgColor: isMobile ? '#EB5757' : theme.blue4,
                color: theme.white,
              },
            ]}
          />
          <Spacer />
          <UnbiasedResources isMobile={isMobile} />
          <Spacer />
          <Spacer />
        </>
      ) : (
        <>
          <Spacer />
          <UnbiasedResources />
          <Spacer />
          <Spacer />
          <IconFocusCTA
            icon={<img src={BalloonDog} alt="balloon animal dog" />}
            title={{
              text: isMobile ? null : 'Start saving on your debt today',
              size: 'l',
            }}
            paragraph={
              isMobile
                ? null
                : 'Try the Debt Optimizer to explore your best-available options. No strings attached'
            }
            ctas={[
              {
                text: 'Get Started',
                callback: onSignUp,
                bgColor: isMobile ? '#EB5757' : theme.blue4,
                color: theme.white,
              },
            ]}
          />
          <Spacer />
        </>
      )}
    </div>
  );
};

export const HomeStyled = styled(Home)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 1200px;
  box-sizing: border-box;
  overflow-x: visible;
  padding: 0 0 16px 0;

  @media (max-width: 1079px) {
    margin-top: 48px;
  }
`;

export default HomeStyled;
