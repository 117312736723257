import { getQueryParams } from '../utils';

// Add the Segment script to the head of the document
if (process.env.SEGMENT === 'true') {
  const target = document.getElementsByTagName('head');
  const newScript = document.createElement('script');
  const inlineScript = document.createTextNode(`!(function () {
  var analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.')
    else {
      analytics.invoked = !0
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ]
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e]
        analytics[key] = analytics.factory(key)
      }
      analytics.load = function (key, e) {
        var t = document.createElement('script')
        t.type = 'text/javascript'
        t.async = !0
        t.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js'
        var n = document.getElementsByTagName('script')[0]
        n.parentNode.insertBefore(t, n)
        analytics._loadOptions = e
      }
      analytics._writeKey = 'wmEbcktdTwhkc4hV4KcB60aiCtZWnAjW'
      analytics.SNIPPET_VERSION = '4.15.3'
      analytics.load('wmEbcktdTwhkc4hV4KcB60aiCtZWnAjW')
    }
})()`);
  newScript.appendChild(inlineScript);
  target[0]?.appendChild(newScript);
}

interface IGAParams {
  utmCampaign?: string | null;
  utmSource?: string | null;
  utmContent?: string | null;
  utmMedium?: string | null;
  referrer?: string | null;
  context: any;
}

const params: IGAParams = {
  context: {},
};

export const getUtmParams = async () => {
  const referrer = document.referrer;
  const queryParams = getQueryParams();

  const utmCampaign =
    localStorage.getItem('utm_campaign') || queryParams['utm_campaign'];
  if (utmCampaign) params.utmCampaign = utmCampaign;

  const utmSource =
    localStorage.getItem('utm_source') || queryParams['utm_source'];
  if (utmSource) params.utmSource = utmSource;

  const utmContent =
    localStorage.getItem('utm_content') || queryParams['utm_content'];
  if (utmContent) params.utmContent = utmContent;

  const utmMedium =
    localStorage.getItem('utm_medium') || queryParams['utm_medium'];
  if (utmMedium) params.utmMedium = utmMedium;

  const nextReferrer = localStorage.getItem('referrer') || referrer;
  if (nextReferrer) params.referrer = nextReferrer;

  return params;
};

export const segmentIdentify = async ({ firebaseUser, traits }) => {
  window.analytics &&
    window.analytics.identify &&
    window.analytics.identify(firebaseUser.uid, {
      ...traits,
      traits
    });
};

let queue = [];

export const trackImpression = (name: Track, properties: SectionProperties) => {
  queue.push({ name, properties });

  if (window?.analytics?.track) {
    const currQueue = [...queue];
    queue = [];

    currQueue.forEach(({ name, properties }) => {
      window.analytics.track(name, properties);
    });
  }
};

setInterval(() => {
  if (queue.length && window?.analytics?.track) {
    const currQueue = [...queue];
    queue = [];

    currQueue.forEach(({ name, properties }) => {
      window.analytics.track(name, properties);
    });
  }
}, 1000);
