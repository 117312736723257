// Utils
import LogRocket from '../../analytics/logrocket';
import { getUtmParams, segmentIdentify } from '../../analytics/segment';
import { updateIdentity } from '../../services/user.service';
import { obfuscateEmail } from '../../utils';

export const identifyUser = async (firebaseUser, user) => {
  const snapshots = user?.snapshots?.length > 0 ? user.snapshots : null;

  const traits: { [key: string]: number | boolean | string } = {
    addDateTime: user?.addDateTime,
    firstName: user?.names[0].first,
    obfsml: obfuscateEmail(firebaseUser?.email || ''),
    lastName: user?.names[0].last,
    state: user?.currentAddress.state,
    zipCode: user?.currentAddress.zipCode,
    homeValue: user?.homeValue,
    emailConsent: user?.emailConsent,
    smsConsent: user?.smsConsent,
    ownOrRent: user?.ownOrRent,
    rentMonthly: user?.rent,
    incomeMonthly: snapshots
      ? snapshots?.[snapshots.length - 1]?.value?.income?.monthly?.gross
      : null,
    expensesMonthly: snapshots
      ? snapshots?.[snapshots.length - 1]?.value?.expenses?.monthly
      : null,
  };

  if (user?.subscriptionType) {
    traits.subscriptionType = user.subscriptionType;
  }

  if (firebaseUser?.email) {
    traits.email = firebaseUser.email;
  }

  LogRocket.getSessionURL((sessionURL) => {
    if (sessionURL) {
      traits.logRocketSession = sessionURL;
    }
  });

  // Update the user in Segment with the latest user traits we have saved
  segmentIdentify({ firebaseUser, traits });

  const params = await getUtmParams();
  await updateIdentity({ params });

  // Attach the user identity to LogRocket for continuity
  try {
    LogRocket?.identify(firebaseUser.uid, traits);
  } catch (e) {
    // Do nothing...
  }
};
